
import {Component, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipEtiketiForm from "@/components/forms/TakipEtiketiForm.vue";
import {TahsilatTuruLabel} from "@/enum/TahsilatTuru";
import AltTahsilatTuruForm from "@/components/forms/AltTahsilatTuruForm.vue";

@Component({
  components: {AltTahsilatTuruForm, TakipEtiketiForm, FormDialog, DeleteDialog},
})
export default class AltTahsilatTuruList extends Vue {

  TahsilatTuruLabel = TahsilatTuruLabel;
  items = [];
  headers = [
    {text: "İsim", value: "isim", class: 'my-header-style'},
    {text: "Tahsilat Türü", value: "tahsilat_turu", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'end', class: 'header-actions-style'},
  ];

  mounted() {
    this.loadAltTahsilatTurleri();
  }

  loadAltTahsilatTurleri() {
    this.$http.get("/api/v1/alt-tahsilat-turu").then((response) => {
      this.items = response.data;
    });
  }
}

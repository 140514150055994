
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import NotEditor from "@/components/inputs/NotEditor.vue";
import {NotSablonEntity} from "@/entity/NotSablonEntity";


@Component({
  components: {NotEditor, FormWrapper},
})

export default class NotSablonForm extends Mixins(ObjectInputMixin){
localValue!: NotSablonEntity;

  save(){
    if(this.localValue.id){
      this.$http.put('/api/v1/not-sablon/'+this.localValue.id,this.localValue).then(()=>{
        this.$emit("onSuccess");
      });
    }
    else{
      this.$http.post('/api/v1/not-sablon',this.localValue).then(()=>{
        this.$emit("onSuccess");
      });
    }
  }
}

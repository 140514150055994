
import { Component, Mixins } from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";

@Component({
  components: { DatabasePicker },
})
export default class VekaletUcretiTuruPicker extends Mixins(PickerInputMixin) {
  items: Array<any> = [];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/vekalet-ucreti").then((response) => {
      this.items = response.data;
    });
  }
}

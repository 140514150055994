export enum EkAlanTuru{
    text="text",
    date="date",
    integer="integer",
    boolean="boolean"
}

export enum EkAlanTuruLabel{
    text="Metin",
    date="Tarih",
    integer="Sayı",
    boolean="Evet/Hayır"
}
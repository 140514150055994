

import {Component, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import NotSablonForm from "@/components/forms/NotSablonForm.vue";
import {NotSablonEntity} from "@/entity/NotSablonEntity";

@Component({
  components: {NotSablonForm, DeleteDialog, FormDialog},
})

export default class NotSablonList extends Vue {
  items: Array<NotSablonEntity> = [];

  headers = [
    {text: "Şablon Adı", value: "isim", class: 'my-header-style'},
    {text: "Şablon", value: "not", sortable: false, class: 'my-header-style'},
    {text: "İşlemler", value: "actions", sortable: false, align: 'end', width: '100', class: 'header-actions-style'}
  ];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/not-sablon").then((response: any) => {
      this.items = response.data;
    });
  }
}


import {Component, Vue} from "vue-property-decorator";
import {HarcDonemiEntity} from "@/entity/HarcDonemiEntity";
import {AxiosResponse} from "axios";
import HarcCesitleriList from "@/components/lists/HarcCesitleriList.vue";

@Component({
  components: {
    HarcCesitleriList,
  },
})
export default class HarclarInfo extends Vue {
  items: Array<HarcDonemiEntity> = [];
  selectedItem: HarcDonemiEntity = new HarcDonemiEntity();

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/harc-donemi").then((response: AxiosResponse<Array<HarcDonemiEntity>>) => {
      this.items = response.data;
      this.items.map(item => item.text = item.baslangic_tarihi ? this.$helper.tarihStr(item.baslangic_tarihi) : "");
      this.items.reverse();
      this.selectedItem = this.items[0];
    });
  }
}


import { Component, Mixins } from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
  components: {Tutar, Dates, FormWrapper },
})
export default class MasrafCesitleriForm extends Mixins(ObjectInputMixin) {}

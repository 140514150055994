
import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import FaizTuruForm from "@/components/forms/FaizTuruForm.vue";
import {HesapSekliLabel} from "@/enum/HesapSekli";

@Component({
  components: {FormDialog, DeleteDialog, FaizTuruForm},
})
export default class FaizTuruList extends Vue {
  @Prop() takipTarihi!: Date;
  items: Array<FaizTuruEntity> = [];
  hesapSekli = HesapSekliLabel;
  dialog: boolean = false;

  search: string = "";
  headers = [
    {text: "Faiz Adı", align: "start", sortable: false, value: "isim", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", align: 'end', width: '100', class: 'header-actions-style'},
  ];
  get defaultFaizTuru(){
    return new FaizTuruEntity();
  }

  mounted() {
    this.load(true);
  }

  load(dontEmitSuccess: boolean = false) {
    if (this.$route.params.id) {
      this.$http.get("/api/v1/faiz-turu").then((response) => {
        this.items = response.data;
        if (!dontEmitSuccess)
          this.$emit('onSuccess');
      });
    } else {
      this.$http.get("/api/v1/faiz-turu/filter?aktif_mi=1").then((response) => {
        this.items = response.data.filter((faizTuru:any) => !faizTuru.takip_id);
        this.$emit('onSuccess');
      });
    }
  }
}

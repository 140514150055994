
import { Component, Mixins } from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import {TakipDurumu, TakipDurumuLabel} from "@/enum/TakipDurumu";
import {TakipEtiketiEntity} from "@/entity/TakipEtiketiEntity";

@Component({
  components: {EnumPicker, FormWrapper},
})
export default class TakipEtiketiForm extends Mixins(ObjectInputMixin) {
  takipDurumu = [TakipDurumu.acik, TakipDurumu.kapali];
  takipDurumuLabel= [TakipDurumuLabel.acik, TakipDurumuLabel.kapali];
  localValue:TakipEtiketiEntity = new TakipEtiketiEntity();
}


import {Component, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipAltTipForm from "@/components/forms/TakipAltTipForm.vue";

import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";

@Component({
  components: {FormDialog, DeleteDialog, TakipAltTipForm},
})
export default class TakipAltTipList extends Vue {
  items = [];

  headers = [
    {text: "Takip Alt Tip Adı", align: "start", sortable: false, value: "isim", class: 'my-header-style'},
    {text: "Takip Tip", align: "start", sortable: false, value: "takip_tipi", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'end', class: 'header-actions-style'},
  ];

  mounted() {
    this.loadTakipAltTip();
  }

  loadTakipAltTip() {
    this.$http.get("/api/v1/takip-alt-tipi").then((response) => {
      this.items = response.data;
    });
  }

  createTakipAltTipHesapAyar(takipAltTip: any) {
    this.loadTakipAltTip();
    let data = {
      takip_alt_tipi_id: takipAltTip.id,
      alacak_kalemi_alani_id: 1,
    };
    this.$http.post("/api/v1/takip-alt-tip-hesap-ayar", data).then(() => {
    });
  }

  updateTakipAltTip(data: any) {
    this.$http.put(`/api/v1/takip-alt-tipi/${data.id}`, data).then(() => {
      this.loadTakipAltTip();
    });
  }

  detay(item: TakipAltTipiEntity) {
    this.$router.push("takip-alt-tip/" + item.id!.toString());
  }
}

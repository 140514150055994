
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "../../mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import {EkAlanTuru, EkAlanTuruLabel} from "@/enum/EkAlanTuru";
import {TakipEkAlanTuruEntity} from "@/entity/TakipEkAlanTuruEntity";

@Component({
  components: {EnumPicker, FormWrapper},
})

export default class TakipEkAlanTuruForm extends Mixins(ObjectInputMixin) {
  ekAlanTuru = EkAlanTuru;
  ekAlanTuruLabel = EkAlanTuruLabel;
  localValue:TakipEkAlanTuruEntity=new TakipEkAlanTuruEntity();

  save(){
    this.localValue.kod = this.formatName(this.localValue.isim);
    if(this.localValue.id){
      this.$http.put('/api/v1/takip-ek-alan-turu/'+this.localValue.id,this.localValue).then(()=>{
        this.$emit("onSuccess");
      });
    }
    else{
      this.$http.post('/api/v1/takip-ek-alan-turu',this.localValue).then(()=>{
        this.$emit("onSuccess");
      });
    }
  }

  formatName(name: string){
    let searchChar = ['ç', 'ğ', 'ş', 'ü', 'ı', 'ö', ' '];
    let replaceChar = ['c', 'g', 's', 'u', 'i', 'o', '_'];
    name = name.toLowerCase();
    for (let i=0; i<7;i++){
      name = name.replace(new RegExp(searchChar[i],'g'),replaceChar[i]);
    }
    return name;
  }
}

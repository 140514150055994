
import {Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import VekaletUcretiTuruPicker from "@/components/pickers/VekaletUcretiTuruPicker.vue";
import FaizTuruPicker from "@/components/pickers/FaizTuruPicker.vue";
import HesapSekliPicker from "@/components/pickers/HesapSekliPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components: {
    FormWrapper,
    HesapSekliPicker,
    FaizTuruPicker,
    TakipTipiPicker,
    VekaletUcretiTuruPicker,
    ParaBirimiPicker,
  },
})
export default class TakipAltTipForm extends Mixins(ObjectInputMixin) {


  faizMetniGoster:boolean=false;


  @Watch("localValue.takip_tipi_id")
  onTakipTalebi(){
    if(this.localValue.takip_talebi_metni=='' || this.localValue.takip_talebi_metni==null){
      this.localValue.takip_talebi_metni="(Toplam Alacak Tutarı) tutarındaki toplam alacağın icra gideri, vekalet.ücreti ve takip tarihinden itibaren asıl alacağa işleyecek ( {{faizAciklamasi}} ) faiz ile tahsili talebidir. (Fazlaya dair ve faiz oranlarındaki artıştan doğan talep hakkımız saklıdır) TBK. 100.mad. gereğince kısmi ödemeler öncelikle işlemiş faiz, masraf ve fer’ilere mahsup edilecektir."
    }
  }

  @Watch("localValue")
  onTakipTalebiMetni(){
    if(this.localValue.takip_talebi_metni.indexOf('{{faizAciklamasi}}')<0){
      this.faizMetniGoster=true;
    }else{
      this.faizMetniGoster=false;
    }
  }

}

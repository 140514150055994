
import {Component, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipEkAlanTuruForm from "@/components/forms/TakipEkAlanTuruForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {TakipEkAlanEntity} from "@/entity/TakipEkAlanEntity";
import {EkAlanTuruLabel} from "@/enum/EkAlanTuru";

@Component({
  components: {DeleteDialog, TakipEkAlanTuruForm, FormDialog},
})

export default class TakipEkAlanList extends Vue {
  items: Array<TakipEkAlanEntity> = [];
  headers = [
    {text: "Alan Adı", value: "isim", class: 'my-header-style'},
    {text: "Alan Türü", value: "tur", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", sortable: false, width: '100', align: 'end', class: 'header-actions-style'}
  ];
  EkAlanTuruLabel = EkAlanTuruLabel

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/takip-ek-alan-turu").then((response) => {
      this.items = response.data;
    });
  }
}
